import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("b2b/business/club/relation/list", { params });
}

export function Add(params) {
  return request.post("b2b/business/club/relation/", params);
}

export function Delete(data) {
  return request.delete("b2b/business/club/relation/", { data });
}

<template>
  <div>
    <a-modal v-model:visible="visible" title="选择关注的医院" @ok="visible = false" okText="完成" cancelText="取消"
      :afterClose="closeModal" :cancelButtonProps="{ style: { display: 'none' } }" width="20vw">

      <a-checkbox class="checkbox" v-for="item in data" :checked="item.checked" @change="onChange($event, item)">
        {{ item.clubName }}
      </a-checkbox>

    </a-modal>
  </div>
</template>

<script>
import { Search, Add, Delete } from "/src/api/b2b/monthlyReports.js";
export default {
  components: {
  },
  data() {
    return {
      activeKey: "",
      visible: this.modelValue,
      reload: false,
      selectedRows: {},
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
    this.detail()
  },
  activated() {
    this.detail()
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filter() {
      return true
    },
    async detail() {
      let res = await Search({ clubCategory: "门诊部" })
      // console.log(res)
      if (res.errCode == 0) {
        this.data = res.data
      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async onChange(e, target) {
      console.log(target);
      console.log(this.data);

      let id = this.data.find(item => item.clubName == target.clubName).clubId
      if (e.target.checked) {
        await Add({ clubId: id })
      } else {
        await Delete({ clubId: id })
      }
      this.detail()
      this.$emit('updateOption')
    },
    closeModal() {
      this.$emit('update:modelValue', false);
      //   if (this.reload) {
      //     this.$emit("reload")
      //   }
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}

.head span {
  font-weight: bold;
  margin-right: 10px;
}

.checkbox {
  display: flex;
  flex-wrap: wrap;
}

.checkbox label {
  width: 20%;
  margin: 15px 10px;
}

.maxHeight {
  max-height: 60vh;
  overflow-y: auto;
}

.checkbox {
  display: block;
  margin: 0;
  margin-bottom: 10px;
}
</style>
